<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            14. Порядок хранения печатной продукции и документов
          </v-card-title>
        </v-col>
        <v-col cols="12">
          <v-card-text class="title font-weight-light">
            <p>По функциональной пожарной опасности архивные помещения относятся к классу
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on }">
                 <span
                  v-on="on"
                  class="mb-2 blue lighten-4"
                  >
                    Ф5.2.
                  </span>
                </template>
                <span class="pa-5 subtitle-1 pl-0"><strong>Ф5.2 - складские здания, сооружения, стоянки для автомобилей<br> без технического обслуживания и ремонта, книгохранилища, <br>архивы, складские помещения.</strong></span>
              </v-tooltip>
            </p>
            <p>Сотрудники архива допускаются к работе только после проведения вводного и первичного инструктажей по ПБ.</p>
          </v-card-text>
          <v-card-title >
            Требования
          </v-card-title>
        </v-col>
        <v-col cols="12">
          <v-tabs color="red" v-model="tab" grow>
            <v-tab>К помещению</v-tab>
            <v-tab>К сотрудникам</v-tab>
            <v-tab>К хранению документов</v-tab>
          </v-tabs>
          <v-tabs-items vertical v-model="tab">
            <v-tab-item>
             <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat
                      >
                      <v-img
                        lazy-src="img/material/fire-truck.svg"
                        max-width="80"
                        src="img/material/fire-truck.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        открытый проезд для пожарных машин
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat
                      >
                      <v-img
                        lazy-src="img/material/hose.svg"
                        max-width="80"
                        src="img/material/hose.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        исправное состояние пожарных гидрантов на территории, приближенной к помещению
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat
                      >
                      <v-img
                        lazy-src="img/material/sand-bucket.svg"
                        max-width="80"
                        src="img/material/sand-bucket.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        средства пожаротушения, расположенные на улице, очищены ото льда и снега
                      </v-card-text>
                    </v-card>

                  </v-col>
                  <v-col cols="6">
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                      <v-img
                        lazy-src="img/material/fire-alarm.svg"
                        max-width="80"
                        src="img/material/fire-alarm.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        помещение должно быть оборудовано системами автоматической пожарной, охранной сигнализации, сертифицированными первичными устройствами пожаротушения
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                      <v-img
                        lazy-src="img/material/exit.svg"
                        max-width="80"
                        src="img/material/exit.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        наличие объемных самосветящихся изображений, указывающих пути эвакуации
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col offset="3">
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                      <v-img
                        lazy-src="img/material/cleaning-products.svg"
                        max-width="80"
                        src="img/material/cleaning-products.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        соблюдать чистоту помещения, ежедневно выносить мусор
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
             </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col cols="6">
                  <v-card-title class="title font-weight-bold">
                    После завершения работы
                  </v-card-title>
                  <v-card-text class="title font-weight-light">
                    сотрудники, ответственные за соблюдение противопожарного режима или работник, покидающий помещение последним, обязан:
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon size="40" color="red">mdi-numeric-1</v-icon>
                    провести визуальную оценку пожарного состояния архивного помещения;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon size="40" color="red">mdi-numeric-2</v-icon>
                    закрыть каждое окно;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon size="40" color="red">mdi-numeric-3</v-icon>
                    отключить освещение и все электрооборудование;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon size="40" color="red">mdi-numeric-4</v-icon>
                    покинуть архивную комнату и закрыть за собой дверь.
                  </v-card-text>
                </v-col>
                <v-col>
                  <v-card-title class="title red--text font-weight-bold">
                    В архивных помещениях ЗАПРЕЩЕНО:
                  </v-card-title>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    оставлять без контроля работу электроприборов;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    перегораживать пути эвакуации, проходы к средствам пожаротушения;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    курить;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    выполнять огнеопасные работы без специального допуска;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    использовать электронагревательные приспособления, дежурное освещение.
                  </v-card-text>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
             <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat
                      >
                      <v-img
                        lazy-src="img/material/cabinet.svg"
                        max-width="80"
                        src="img/material/cabinet.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        <p>Если использование стеллажей не предусмотрено, то контейнеры размещают штабелями.
                          <v-tooltip bottom color="red">
                            <template v-slot:activator="{ on }">
                            <span
                              v-on="on"
                              class="mb-2 blue lighten-4"
                              >
                                Поперечные проемы
                              </span>
                            </template>
                            <span class="pa-5 subtitle-1 pl-0"><strong>(ширина не более 80 см)</strong></span>
                          </v-tooltip>
                        оборудуются через каждые 6 м.</p>

                      </v-card-text>
                    </v-card>
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat
                      >
                      <v-img
                        lazy-src="img/material/cabinet-2.svg"
                        max-width="80"
                        src="img/material/cabinet-2.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        Стеллажи или контейнеры для хранения документации собираются из металлических деталей
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat
                      >
                      <v-img
                        lazy-src="img/material/dimension.svg"
                        max-width="80"
                        src="img/material/dimension.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        Перед дверными проемами предусматриваются свободные проходы шириной, равно дверям и не менее 1м.
                      </v-card-text>
                    </v-card>

                  </v-col>
                  <v-col cols="6">
                    <v-card-title class="title font-weight-bold">
                      Стеллажи должны быть расположены<br> на минимальном расстоянии:
                    </v-card-title>
                    <v-card-text class="title font-weight-light mb-0 pb-0">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      1 м от насадков и наросителей;
                    </v-card-text>
                    <v-card-text class="title font-weight-light mb-0 pb-0">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      0,5 м от лампочек;
                    </v-card-text>
                    <v-card-text class="title font-weight-light mb-0 pb-0">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      0,6 м от пожарных извещателей;
                    </v-card-text>
                  </v-col>
                </v-row>
             </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <Hint></Hint>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab: null
  }),
  components: {
    Hint
  }
}
</script>
